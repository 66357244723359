//==============================================================================
// PDP Conditional Container
//
// PDP container module that can be disabled to hide child content using a
// toggle or configurable product conditions.
//
// Currently supports product type attribute, but this module supports adding
// more attribute conditions as needed.
//==============================================================================
import * as React from 'react';

import { attrNames } from '../../utilities/global-constants';
import { convertProductAttributes, AttributesWithMetadata } from '../../utilities/data-attribute-parser';

import { IPdpConditionalContainerData } from './pdp-conditional-container.data';
import { IPdpConditionalContainerProps, IConditionsData } from './pdp-conditional-container.props.autogenerated';

//==============================================================================
// INTERFACES
//==============================================================================
export interface ConditionResult {
    condition: string;
    pass: boolean;
}

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * PdpConditionalContainer component
 * @extends {React.PureComponent<IPdpConditionalContainerProps<IPdpConditionalContainerData>>}
 */
//==============================================================================
class PdpConditionalContainer extends React.PureComponent<IPdpConditionalContainerProps<IPdpConditionalContainerData>> {
    //==========================================================================
    // VARIABLES
    //==========================================================================
    private conditionResults: ConditionResult[] = [];

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================
    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element | null {
        const { config, slots } = this.props;
        const hasSlots = !!slots?.contentBlock?.length;

        if (hasSlots) {
            const isEnabled = config.enabled;
            const conditions = config.conditions;
            const operator = conditions?.operator;

            // Only show content if:
            // 1. Enabled config is true AND
            // 2. (No operator is set up; all conditions ignored) OR (Operator is set up; all conditions pass)
            const showContent = isEnabled && (!operator || (operator && conditions && this._passConditions(operator, conditions)));

            if (showContent) {
                return (
                    <React.Fragment>
                        {isEnabled && this._renderSlotItems(slots.contentBlock)}
                    </React.Fragment>
                );
            }
        }

        // No slots, so there's nothing to do
        return null;
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================
    //------------------------------------------------------
    // Render children content
    //------------------------------------------------------
    private readonly _renderSlotItems = (items: React.ReactNode[]): JSX.Element => {
        return (
            <React.Fragment>
                {items.map((slot: React.ReactNode, index: number) => (
                    <React.Fragment key={index}>
                        {slot}
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    };

    //------------------------------------------------------
    // Check if conditions pass operator test for render
    //------------------------------------------------------
    private readonly _passConditions = (operator: string, conditions: IConditionsData): boolean => {
        // Map each condition list to corresponding condition check method
        const conditionsMap = {
            productTypeConditions: this._checkProductTypeCondition
        };

        // Loop over and parse through each condition list and condition
        Object.keys(conditionsMap).forEach(key => {
            // Check first if specific condition list exists in configured conditions
            conditions[key] && this._parseConditions(conditions[key], conditionsMap[key]);
        });

        // If no conditions are set up, immediately return true
        if (!this.conditionResults.length) {
            return true;
        }

        // Map each operator to a test that determines if conditions are met
        const operatorMap = {
            all: !this._findConditionResult(false),
            one: !!this._findConditionResult(true),
            none: !this._findConditionResult(true)
        };

        const operatorTest = operatorMap[operator];
        return operatorTest;
    };

    //------------------------------------------------------
    // Find desired condition result to pass each operator
    // test
    //------------------------------------------------------
    private readonly _findConditionResult = (result: boolean): ConditionResult | undefined => {
        return this.conditionResults.find(condition => condition.pass === result);
    };

    //------------------------------------------------------
    // Parse through condition list to check each condition
    //------------------------------------------------------
    private readonly _parseConditions = (conditionList: any, checkCondition: Function): void => {
        conditionList.forEach((condition: any) => checkCondition(condition));
    };

    //------------------------------------------------------
    // Push condition to condition results list
    //------------------------------------------------------
    private readonly _pushCondition = (condition: string, pass: boolean): void => {
        const conditionExists = this.conditionResults.find(result => result.condition === condition);
        !conditionExists && this.conditionResults.push({ condition, pass });
    };

    //------------------------------------------------------
    // Format condition name
    //------------------------------------------------------
    private readonly _formatConditionName = (conditionType: string, conditionName: string): string => {
        // Transform to lowercase and remove all spaces
        const strippedName = conditionName.toLowerCase().replace(/\s/g, '');
        return `${conditionType}-${strippedName}`;
    };

    //------------------------------------------------------
    // Check product type condition
    //------------------------------------------------------
    private readonly _checkProductTypeCondition = (productTypeCondition: string): void => {
        const condition: string = this._formatConditionName('productType', productTypeCondition);

        // Push true if product type value matches product type condition
        if (this._checkProductType(productTypeCondition)) {
            this._pushCondition(condition, true);
        } else {
            this._pushCondition(condition, false);
        }
    };

    //------------------------------------------------------
    // Checks if product type value matches product type
    // condition
    //------------------------------------------------------
    private readonly _checkProductType = (productTypeCondition: string): boolean => {
        const convertedAttributes = this._getConvertedAttributes();
        const currentProductType = convertedAttributes && convertedAttributes[attrNames.productType];
        const matchProductType = currentProductType === productTypeCondition;
        return matchProductType;
    };

    //------------------------------------------------------
    // Get converted product attributes
    //------------------------------------------------------
    private readonly _getConvertedAttributes = (): AttributesWithMetadata | undefined => {
        const { data } = this.props;
        const productAttributes = data.productSpecificationData?.result;
        return productAttributes && convertProductAttributes(productAttributes);
    };
}

export default PdpConditionalContainer;
